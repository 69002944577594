import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    user: null,
    language: null,
    inaguration: null,
    navMenuItems: [],
    cart: localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : [],
  },
  getters: {
    getLoggedInUser: (state) => {
      return state.user;
    },
    hasPermission: (state) => (name) => {
      if (state.user) {
        return state.user.permissions.hasOwnProperty(name);
      }
      return false;
    },
    hasRole: (state) => (name) => {
      if (state.user.role_data.code_name === name) {
        return true;
      }
      return false;
    },
    language: (state) => {
      return state.language;
    },
    inaguration: (state) => {
      return state.inaguration;
    },
    isLoggedIn: (state) => state.isLoggedIn,
    getNavMenuItems: (state) => state.navMenuItems,
    getCart: (state) => state.cart,
  },
  mutations: {
    login(state, data) {
      state.isLoggedIn = true;
      state.user = data.user;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
    },
    setLanguage(state, data) {
      state.language = data;
    },
    setInaguration(state, data) {
      state.inaguration = data;
    },
    setNavMenuItems(state) {
      state.navMenuItems = [];

      if (state.user.permissions.hasOwnProperty("show_user")) {
        state.navMenuItems.push({
          title: "User",
          icon: "UserIcon",
          route: "User",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_role")) {
        state.navMenuItems.push({
          title: "Role",
          icon: "AwardIcon",
          route: "Role",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_department")) {
        state.navMenuItems.push({
          title: "Department",
          icon: "GridIcon",
          route: "AddDepartment",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_hospital")) {
        state.navMenuItems.push({
          title: "Hospital",
          icon: "ActivityIcon",
          route: "AddHospital",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_procedure")) {
        state.navMenuItems.push({
          title: "Procedure",
          icon: "PlusCircleIcon",
          route: "AddProcedure",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_doctor")) {
        state.navMenuItems.push({
          title: "Doctor",
          icon: "PlusSquareIcon",
          route: "AddDoctor",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_doctor_dashboard")) {
        state.navMenuItems.push({
          title: "Doctor Dashboard",
          icon: "HomeIcon",
          route: "DoctorDashboard",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_security_package")) {
        state.navMenuItems.push({
          title: "Security Package",
          icon: "ShieldIcon",
          route: "AddSecurity",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_visa_assistance")) {
        state.navMenuItems.push({
          title: "Visa Assistance",
          icon: "GlobeIcon",
          route: "AddVisaAssistance",
        });
      }

      const hotelMenuItem = {
        title: "Hotel",
        icon: "HomeIcon",
        children: [],
      };
      if (state.user.permissions.hasOwnProperty("show_hotel")) {
        state.navMenuItems.push(hotelMenuItem);
      }
      if (state.user.permissions.hasOwnProperty("show_hotel")) {
        hotelMenuItem.children.push({
          title: "Add Hotel",
          route: "AddHotel",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_room_category")) {
        hotelMenuItem.children.push({
          title: "Add Room",
          route: "AddRoom",
        });
      }

      const interpratorMenuItem = {
        title: "Interprator Services",
        icon: "GlobeIcon",
        children: [],
      };
      if (state.user.permissions.hasOwnProperty("show_interprator_company")) {
        state.navMenuItems.push(interpratorMenuItem);
      }
      if (state.user.permissions.hasOwnProperty("show_interprator_company")) {
        interpratorMenuItem.children.push({
          title: "Add Company",
          route: "AddInterpratorCompany",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_interprator_service")) {
        interpratorMenuItem.children.push({
          title: "Add Service",
          route: "AddInterpratorService",
        });
      }

      const carRentalMenuItem = {
        title: "Car Rental",
        icon: "TruckIcon",
        children: [],
      };
      if (state.user.permissions.hasOwnProperty("show_rental_car")) {
        state.navMenuItems.push(carRentalMenuItem);
      }
      if (state.user.permissions.hasOwnProperty("show_rental_company")) {
        carRentalMenuItem.children.push({
          title: "Add Company",
          route: "AddRentalCompany",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_rental_car")) {
        carRentalMenuItem.children.push({
          title: "Add Car",
          route: "AddRentalCar",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_patient_dashboard")) {
        state.navMenuItems.push({
          title: "Patient Dashboard",
          icon: "HomeIcon",
          route: "PatientDashboard",
        });
      }

      const touristattractionMenuItem = {
        title: "Tourist Attraction",
        icon: "SunsetIcon",
        children: [],
      };
      if (state.user.permissions.hasOwnProperty("show_tourist_attraction")) {
        state.navMenuItems.push(touristattractionMenuItem);
      }
      if (state.user.permissions.hasOwnProperty("show_tourist_attraction")) {
        touristattractionMenuItem.children.push({
          title: "Add Places",
          route: "AddTourist",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_tourist_trip")) {
        touristattractionMenuItem.children.push({
          title: "Add Trips",
          route: "AddTouristTrip",
        });
      }

      const csrPanelMenuItem = {
        title: "CSR Panel",
        icon: "ShoppingBagIcon",
        children: [],
      };
      if (state.user.permissions.hasOwnProperty("show_csr_dashboard")) {
        state.navMenuItems.push(csrPanelMenuItem);
      }
      if (state.user.permissions.hasOwnProperty("show_create_order")) {
        csrPanelMenuItem.children.push({
          title: "Create Order",
          route: "CreateOrder",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_create_order")) {
        csrPanelMenuItem.children.push({
          title: "Bookings",
          route: "ShowBooking",
        });
      }
      if (state.user.permissions.hasOwnProperty("read_patient")) {
        csrPanelMenuItem.children.push({
          title: "Patient",
          route: "Patient",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_insurance")) {
        state.navMenuItems.push({
          title: "Insurance",
          icon: "GlobeIcon",
          route: "AddInsurance",
        });
      }

      // if (state.user.permissions.hasOwnProperty("show_blog_category")) {
      //   state.navMenuItems.push({
      //     title: "Blog Category",
      //     icon: "GlobeIcon",
      //     route: "AddBlogCategory",
      //   });
      // }
      const blogMenuItem = {
        title: "Blog",
        icon: "BookOpenIcon",
        children: [],
      };
      if (state.user.permissions.hasOwnProperty("show_blog_category")) {
        state.navMenuItems.push(blogMenuItem);
      }
      if (state.user.permissions.hasOwnProperty("show_blog_category")) {
        blogMenuItem.children.push({
          title: "Add Blog Category",
          route: "AddBlogCategory",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_blog")) {
        blogMenuItem.children.push({
          title: "Add Blog",
          route: "AddBlog",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_create_order")) {
        csrPanelMenuItem.children.push({
          title: "Consultations",
          route: "ShowConsultations",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_gallery")) {
        state.navMenuItems.push({
          title: "Gallery",
          icon: "ImageIcon",
          route: "AddGallery",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_faq")) {
        state.navMenuItems.push({
          title: "FAQ",
          icon: "GlobeIcon",
          route: "AddFAQ",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_quote")) {
        state.navMenuItems.push({
          title: "Quote",
          icon: "ClipboardIcon",
          route: "Quote",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_overseas_pakistani")) {
        state.navMenuItems.push({
          title: "Overseas Quote",
          icon: "ClipboardIcon",
          route: "OverseasQuote",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_testimonial")) {
        state.navMenuItems.push({
          title: "Testimonial",
          icon: "UserCheckIcon",
          route: "AddTestimonial",
        });
      }

      if (state.user.permissions.hasOwnProperty("show_company")) {
        state.navMenuItems.push({
          title: "Referral Company",
          icon: "CompassIcon",
          route: "ReferralCompany",
        });
      }
      if (state.user.permissions.hasOwnProperty("show_health_package")) {
        state.navMenuItems.push({
          title: "Health Packages",
          icon: "PlusCircleIcon",
          route: "HealthPackages",
        });
      }
    },

    addToCart(state, item) {
      item["myItemId"] = Date.now();
      state.cart.push(item);
      // let cartItem = state.cart.find((cartItem) => cartItem.id === item.id);
      // if (cartItem) {
      //   cartItem.quantity += 1;
      // } else {
      //   state.cart.push(item);
      //   // cartItem.viewCart = true;
      // }
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    removeFromCart(state, item) {
      state.cart = state.cart.filter(
        (cartItem) => cartItem.myItemId !== item.myItemId
      );
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    clearCart(state) {
      state.cart = [];
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    // New mutation to increase the number of days
    increaseDays(state, item) {
      const cartItem = state.cart.find((cartItem) => cartItem.id === item.id);
      if (cartItem) {
        cartItem.numberOfDays += 1;
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },

    decreaseDays(state, item) {
      const cartItem = state.cart.find((cartItem) => cartItem.id === item.id);
      if (cartItem && cartItem.numberOfDays > 1) {
        cartItem.numberOfDays -= 1;
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },
  },
  actions: {
    setUserInaguration({ commit }, payload) {
      commit("setInaguration", payload);
    },
    setUserLanguage({ commit }, payload) {
      commit("setLanguage", payload);
    },
    async login({ commit }, payload) {
      const res = await axiosIns.post("login", payload);
      if (res.status === 200) {
        commit("login", res.data);
        commit("setNavMenuItems");
      }
      return res;
    },
    async logout({ commit }) {
      const res = await axiosIns.post("logout");
      if (res.status === 204) {
        commit("logout");
      }
      return res;
    },
    async getCurrentUser({ commit }) {
      const res = await axiosIns.get("current");
      if (res.status === 200) {
        commit("login", res.data);
        commit("setNavMenuItems");
      }
    },
    async getRoles({ commit }, { pageNumber, name }) {
      let url = `roles/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getRole({ commit }, { pk }) {
      const res = await axiosIns.get(`roles/${pk}/`);
      return res;
    },
    async createRole({ commit }, payload) {
      const res = await axiosIns.post("roles/", payload);
      return res;
    },
    async updateRole({ commit }, { payload, pk }) {
      const res = await axiosIns.put(`roles/${pk}/`, payload);
      return res;
    },
    async deleteRole({ commit }, { pk }) {
      const res = await axiosIns.delete(`roles/${pk}/`);
      return res;
    },
    async getPermissions({ commit }) {
      const res = await axiosIns.get("permissions");
      return res;
    },
    async getUsers({ commit }, { pageNumber, name, cnic }) {
      let url = `users/?page=${pageNumber}`;
      if (name) {
        url += `&full_name=${name}`;
      }
      if (cnic) {
        url += `&cnic=${cnic}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getUser({ commit }, { pk }) {
      const res = await axiosIns.get(`users/${pk}/`);
      return res;
    },
    async createUser({ commit }, payload) {
      const res = await axiosIns.post("users/", payload);
      return res;
    },
    async updateUser({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`users/${pk}/`, payload);
      return res;
    },
    async deleteUser({ commit }, { pk }) {
      const res = await axiosIns.delete(`users/${pk}/`);
      return res;
    },

    // Department Functions
    async getDepartments({ commit }, { pageNumber, name, hospital }) {
      let url = `department/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      if (hospital) {
        url += `&hospital=${hospital}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getDepartmentList() {
      let url = `department`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getDepartmentsPublic({ commit }, { hospital }) {
      let url = `departmentpublic/`;
      if (hospital) {
        url = `departmentpublic/?hospital=${hospital}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getDepartmentsPublicNav() {
      let url = `departmentpublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getDepartmentPublic({ commit }, { pk }) {
      const res = await axiosIns.get(`departmentpublic/${pk}/`);
      return res;
    },
    async getDepartment({ commit }, { pk }) {
      const res = await axiosIns.get(`department/${pk}/`);
      return res;
    },
    async createDepartment({ commit }, payload) {
      const res = await axiosIns.post("department/", payload);
      return res;
    },
    async updateDepartment({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`department/${pk}/`, payload);
      return res;
    },
    async deleteDepartment({ commit }, { pk }) {
      const res = await axiosIns.delete(`department/${pk}/`);
      return res;
    },

    // Hospital Functions
    async getHospitals({ commit }, { pageNumber, name }) {
      let url = `hospital/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },
    async getHospitalsPublic() {
      let url = `hospitalpublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getHospitalPublic({ commit }, { pk }) {
      const res = await axiosIns.get(`hospitalpublic/${pk}/`);
      return res;
    },
    async getHospitalList() {
      let url = `hospital/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getHospital({ commit }, { pk }) {
      const res = await axiosIns.get(`hospital/${pk}/`);
      return res;
    },
    async createHospital({ commit }, payload) {
      const res = await axiosIns.post("hospital/", payload);
      return res;
    },
    async updateHospital({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`hospital/${pk}/`, payload);
      return res;
    },
    async deleteHospital({ commit }, { pk }) {
      const res = await axiosIns.delete(`hospital/${pk}/`);
      return res;
    },

    // Doctor Functions
    async getDoctors(
      { commit },
      { pageNumber, full_name, hospital, department, procedure }
    ) {
      let url = `doctor/?page=${pageNumber}`;
      if (full_name) {
        url += `&full_name=${full_name}`;
      }
      if (hospital) {
        url += `&hospital=${hospital}`;
      }
      if (department) {
        url += `&department=${department}`;
      }
      if (procedure) {
        url += `&department=${procedure}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },

    async getDoctorsPublic({ commit }, { hospital, department, pageNumber }) {
      let url = `doctorpublic/?page=${pageNumber}`;

      if (hospital) {
        url += `&hospital=${hospital}`;
      }
      if (department) {
        url += `&department=${department}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },

    async getDoctorsPublicNav() {
      let url = `doctorpublic/`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getDoctorList() {
      let url = `doctor/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getDoctor({ commit }, { pk }) {
      const res = await axiosIns.get(`doctor/${pk}/`);
      return res;
    },
    async createDoctor({ commit }, payload) {
      const res = await axiosIns.post("doctor/", payload);
      return res;
    },
    async updateDoctor({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`doctor/${pk}/`, payload);
      return res;
    },
    async deleteDoctor({ commit }, { pk }) {
      const res = await axiosIns.delete(`doctor/${pk}/`);
      return res;
    },
    async getDoctorOrder({ commit }, { pageNumber }) {
      let url = `doctororders/?page=${pageNumber}`;
      const res = await axiosIns.get(url);
      return res;
    },
    // Procedure Functions
    async getProcedures(
      { commit },
      { pageNumber, name, hospital, department, doctor }
    ) {
      let url = `procedure/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      if (hospital) {
        url += `&hospital=${hospital}`;
      }
      if (doctor) {
        url += `&doctor=${doctor}`;
      }
      if (department) {
        url += `&department=${department}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getProceduresPublic(
      { commit },
      { hospital, department, id, doctor }
    ) {
      let url = ``;
      if (hospital) {
        url = `procedurepublic/?hospital=${hospital}`;
      }
      if (department) {
        url = `procedurepublic/?department=${department}`;
      }

      if (id) {
        url = `procedurepublic/?id=${id}`;
      }
      if (doctor) {
        url = `procedurepublic/?doctor=${doctor}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },

    async getProceduresPublicNav() {
      let url = `procedurepublic/`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getProcedureList() {
      let url = `procedure/`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getProcedure({ commit }, { pk }) {
      const res = await axiosIns.get(`procedure/${pk}/`);
      return res;
    },
    async createProcedure({ commit }, payload) {
      const res = await axiosIns.post("procedure/", payload);
      return res;
    },
    async updateProcedure({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`procedure/${pk}/`, payload);
      return res;
    },
    async deleteProcedure({ commit }, { pk }) {
      const res = await axiosIns.delete(`procedure/${pk}/`);
      return res;
    },

    // Hotels Functions
    async getHotels({ commit }, { pageNumber, name }) {
      let url = `hotel/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getHotelList() {
      let url = `hotel/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getHotelsPublic() {
      let url = `hotelpublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getHotel({ commit }, { pk }) {
      const res = await axiosIns.get(`hotel/${pk}/`);
      return res;
    },
    async createHotel({ commit }, payload) {
      const res = await axiosIns.post("hotel/", payload);
      return res;
    },
    async updateHotel({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`hotel/${pk}/`, payload);
      return res;
    },
    async deleteHotel({ commit }, { pk }) {
      const res = await axiosIns.delete(`hotel/${pk}/`);
      return res;
    },

    // Rental Company Functions
    async getRentalCompanys({ commit }, { pageNumber, name }) {
      let url = `rentalcompany/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getRentalCompanyList() {
      let url = `rentalcompany/`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getRentalCompanysPublic() {
      let url = `rentalcompanypublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getRentalCompany({ commit }, { pk }) {
      const res = await axiosIns.get(`rentalcompany/${pk}/`);
      return res;
    },
    async createRentalCompany({ commit }, payload) {
      const res = await axiosIns.post("rentalcompany/", payload);
      return res;
    },
    async updateRentalCompany({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`rentalcompany/${pk}/`, payload);
      return res;
    },
    async deleteRentalCompany({ commit }, { pk }) {
      const res = await axiosIns.delete(`rentalcompany/${pk}/`);
      return res;
    },

    // Rental Car Functions
    async getRentalCars({ commit }, { pageNumber, name, rental_company }) {
      let url = `rentalcar/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      if (rental_company) {
        url += `&rental_company=${rental_company}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getRentalCarList() {
      let url = `rentalcar/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getRentalCarsPublic({ commit }, { name, rental_company }) {
      let url = `rentalcarpublic/`;
      if (name) {
        url += `?name=${name}`;
      }
      if (rental_company) {
        url += `&rental_company=${rental_company}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getRentalCar({ commit }, { pk }) {
      const res = await axiosIns.get(`rentalcar/${pk}/`);
      return res;
    },
    async createRentalCar({ commit }, payload) {
      const res = await axiosIns.post("rentalcar/", payload);
      return res;
    },
    async updateRentalCar({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`rentalcar/${pk}/`, payload);
      return res;
    },
    async deleteRentalCar({ commit }, { pk }) {
      const res = await axiosIns.delete(`rentalcar/${pk}/`);
      return res;
    },

    // RoomFunctions
    async getRooms({ commit }, { pageNumber, name }) {
      let url = `hotel/room_category?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getRoomList() {
      let url = `hotel/room_category`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getRoom({ commit }, { pk }) {
      const res = await axiosIns.get(`hotel/room_category/${pk}/`);
      return res;
    },
    async createRoom({ commit }, payload) {
      const res = await axiosIns.post("hotel/room_category", payload);
      return res;
    },
    async updateRoom({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`hotel/room_category/${pk}/`, payload);
      return res;
    },
    async deleteRoom({ commit }, { pk }) {
      const res = await axiosIns.delete(`hotel/room_category/${pk}/`);
      return res;
    },

    // Security Packages Functions
    async getSecurity({ commit }, { pageNumber }) {
      let url = `securitypackage/?page=${pageNumber}`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getSecurityList() {
      let url = `securitypackage/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getSecurityPublic() {
      let url = `securitypackagepublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getSecuritySingle({ commit }, { pk }) {
      const res = await axiosIns.get(`securitypackage/${pk}/`);
      return res;
    },
    async createSecurity({ commit }, payload) {
      const res = await axiosIns.post("securitypackage/", payload);
      return res;
    },
    async updateSecurity({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`securitypackage/${pk}/`, payload);
      return res;
    },
    async deleteSecurity({ commit }, { pk }) {
      const res = await axiosIns.delete(`securitypackage/${pk}/`);
      return res;
    },

    // Interprator Company Functions
    async getInterpratorCompany({ commit }, { pageNumber }) {
      let url = `interpratorcompany/?page=${pageNumber}`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getInterpratorCompanyList() {
      let url = `interpratorcompany/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getInterpratorCompanyPublic() {
      let url = `interpratorcompanypublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getInterpratorCompanySingle({ commit }, { pk }) {
      const res = await axiosIns.get(`interpratorcompany/${pk}/`);
      return res;
    },
    async createInterpratorCompany({ commit }, payload) {
      const res = await axiosIns.post("interpratorcompany/", payload);
      return res;
    },
    async updateInterpratorCompany({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`interpratorcompany/${pk}/`, payload);
      return res;
    },
    async deleteInterpratorCompany({ commit }, { pk }) {
      const res = await axiosIns.delete(`interpratorcompany/${pk}/`);
      return res;
    },

    // Interprator Service Functions
    async getInterpratorService({ commit }, { pageNumber }) {
      let url = `interpratorservice/?page=${pageNumber}`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getInterpratorServiceList() {
      let url = `interpratorservice/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getInterpratorServicePublic() {
      let url = `interpratorservicepublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getInterpratorServiceSingle({ commit }, { pk }) {
      const res = await axiosIns.get(`interpratorservice/${pk}/`);
      return res;
    },
    async createInterpratorService({ commit }, payload) {
      const res = await axiosIns.post("interpratorservice/", payload);
      return res;
    },
    async updateInterpratorService({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`interpratorservice/${pk}/`, payload);
      return res;
    },
    async deleteInterpratorService({ commit }, { pk }) {
      const res = await axiosIns.delete(`interpratorservice/${pk}/`);
      return res;
    },

    // Tourist Attraction Service Functions
    async getTouristattraction({ commit }, { pageNumber, name }) {
      let url = `touristattraction/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getTouristattractionList() {
      let url = `touristattraction/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getTouristattractionPublic() {
      let url = `touristattractionpublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getTouristattractionSingle({ commit }, { pk }) {
      const res = await axiosIns.get(`touristattraction/${pk}/`);
      return res;
    },
    async createTouristAttraction({ commit }, payload) {
      const res = await axiosIns.post("touristattraction/", payload);
      return res;
    },
    async updateTouristattraction({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`touristattraction/${pk}/`, payload);
      return res;
    },
    async deleteTouristattraction({ commit }, { pk }) {
      const res = await axiosIns.delete(`touristattraction/${pk}/`);
      return res;
    },

    // Tourist Trip Service Functions
    async getTouristtrip({ commit }, { pageNumber, id, touristattraction }) {
      let url = `touristtrip/?page=${pageNumber}`;
      if (id) {
        url += `&id=${id}`;
      }
      if (touristattraction) {
        url += `&tourist_attraction=${touristattraction}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getTouristtripList() {
      let url = `touristtrip/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getTouristtripPublic({ commit }, { touristAttraction }) {
      let url = `touristtrippublic/`;
      if (touristAttraction) {
        url = `touristtrippublic/?tourist_attraction=${touristAttraction}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getTouristtripSingle({ commit }, { pk }) {
      const res = await axiosIns.get(`touristtrip/${pk}/`);
      return res;
    },
    async createTouristtrip({ commit }, payload) {
      const res = await axiosIns.post("touristtrip/", payload);
      return res;
    },
    async updateTouristtrip({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`touristtrip/${pk}/`, payload);

      return res;
    },
    async deleteTouristtrip({ commit }, { pk }) {
      const res = await axiosIns.delete(`touristtrip/${pk}/`);
      return res;
    },

    // Patient Functions
    async getPatients({ commit }, { pageNumber, passport_number }) {
      let url = `patient/?page=${pageNumber}`;
      if (passport_number) {
        url += `&passport_number=${passport_number}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getPatient({ commit }, { pk }) {
      const res = await axiosIns.get(`patient/${pk}/`);
      return res;
    },
    async getPatientByEmail({ commit }, { email }) {
      const res = await axiosIns.get(`patientunpaginated/?email=${email}`);
      return res;
    },
    async patientSignUp({ commit }, payload) {
      const res = await axiosIns.post("patientregisteration/", payload, {
        withCredentials: false, // Ensures no cookies are sent
        headers: {
          Authorization: "", // Ensures no token is sent
        },
      });
      return res;
    },

    // Visa Assistance Functions
    async getVisaAssistance({ commit }, { pageNumber, name }) {
      let url = `visaassistance/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getVisaAssistanceList() {
      let url = `visaassistance/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getVisaAssistancePublic() {
      let url = `visaassistancepublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getVisaAssistanceSingle({ commit }, { pk }) {
      const res = await axiosIns.get(`visaassistance/${pk}/`);
      return res;
    },
    async createVisaAssistance({ commit }, payload) {
      const res = await axiosIns.post("visaassistance/", payload);
      return res;
    },
    async updateVisaAssistance({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`visaassistance/${pk}/`, payload);
      return res;
    },
    async deleteVisaAssistance({ commit }, { pk }) {
      const res = await axiosIns.delete(`visaassistance/${pk}/`);
      return res;
    },

    // Insurance Functions
    async getInsurance({ commit }, { pageNumber, name }) {
      let url = `insurance/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getInsuranceList() {
      let url = `insurance/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getInsurancePublic() {
      let url = `insurancepublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getInsuranceSingle({ commit }, { pk }) {
      const res = await axiosIns.get(`insurance/${pk}/`);
      return res;
    },
    async createInsurance({ commit }, payload) {
      const res = await axiosIns.post("insurance/", payload);
      return res;
    },
    async updateInsurance({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`insurance/${pk}/`, payload);
      return res;
    },
    async deleteInsurance({ commit }, { pk }) {
      const res = await axiosIns.delete(`insurance/${pk}/`);
      return res;
    },

    // Blog Category

    async getBlogCategory({ commit }, { pageNumber, name }) {
      let url = `blogcategory/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getBlogCategoryList() {
      let url = `blogcategory/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getBlogCategoryPublic() {
      let url = `blogcategorypublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getBlogCategorySingle({ commit }, { pk }) {
      const res = await axiosIns.get(`blogcategory/${pk}/`);
      return res;
    },
    async createBlogCategory({ commit }, payload) {
      const res = await axiosIns.post("blogcategory/", payload);
      return res;
    },
    async updateBlogCategory({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`blogcategory/${pk}/`, payload);
      return res;
    },
    async deleteBlogCategory({ commit }, { pk }) {
      const res = await axiosIns.delete(`blogcategory/${pk}/`);
      return res;
    },

    // Blog

    async getBlog({ commit }, { pageNumber, intro, blogCategory }) {
      let url = `blog/?page=${pageNumber}`;
      if (intro) {
        url += `&intro=${intro}`;
      }
      if (blogCategory) {
        url += `&category=${blogCategory}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getBlogList() {
      let url = `blog/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getBlogPublic() {
      let url = `blogpublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getBlogPublicSingle({ commit }, { pk }) {
      const res = await axiosIns.get(`blogpublic/${pk}/`);
      return res;
    },
    async getBlogSingle({ commit }, { pk }) {
      const res = await axiosIns.get(`blog/${pk}/`);
      return res;
    },
    async createBlog({ commit }, payload) {
      const res = await axiosIns.post("blog/", payload);
      return res;
    },
    async updateBlog({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`blog/${pk}/`, payload);
      return res;
    },
    async deleteBlog({ commit }, { pk }) {
      const res = await axiosIns.delete(`blog/${pk}/`);
      return res;
    },
    //booking functions
    async createBooking({ commit }, payload) {
      const res = await axiosIns.post("bookingcreate/", payload, {
        withCredentials: false, // Ensures no cookies are sent
        headers: {
          Authorization: "", // Ensures no token is sent
        },
      });
      return res;
    },

    async getBooking() {
      let url = `bookinglist/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async deleteBooking({ commit }, { pk }) {
      const res = await axiosIns.delete(`bookingdetail/${pk}/`);
      return res;
    },

    // Order Functions
    async getOrderList({ commit }, { pageNumber }) {
      let url = `order/?page=${pageNumber}`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getOrderList2({ commit }, { pageNumber, status }) {
      let url = `order/?page=${pageNumber}`;
      if (status == "pending") {
        url += `&is_paid=${false}`;
      } else if (status == "paid") {
        url += `&is_paid=${true}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async createOrder({ commit }, payload) {
      const res = await axiosIns.post("order/", payload);
      return res;
    },

    // Consultation Functions
    async sendMeetingLink({ commit }, payload) {
      const res = await axiosIns.post("sendmeetinglink/", payload);
      return res;
    },

    async getConsultations({ commit }, { pageNumber, doctor }) {
      let url = `consultation/?page=${pageNumber}`;
      if (doctor) {
        url += `&doctor=${doctor}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    // Gallery Functions
    async getGallery({ commit }, { pageNumber, name }) {
      let url = `gallery/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getGalleryList() {
      let url = `gallery/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getGalleryPublic({ commit }, { hospital }) {
      let url = `gallerypublic/`;
      if (hospital) {
        url = `gallerypublic/?hospital=${hospital}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getGallerySingle({ commit }, { pk }) {
      const res = await axiosIns.get(`gallery/${pk}/`);
      return res;
    },
    async createGallery({ commit }, payload) {
      const res = await axiosIns.post("gallery/", payload);
      return res;
    },
    async updateGallery({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`gallery/${pk}/`, payload);
      return res;
    },
    async deleteGallery({ commit }, { pk }) {
      const res = await axiosIns.delete(`gallery/${pk}/`);
      return res;
    },

    // FAQ

    async getFAQ({ commit }, { pageNumber, question }) {
      let url = `faq/?page=${pageNumber}`;
      if (question) {
        url += `&question=${question}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getFAQList() {
      let url = `faq/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getFAQPublic() {
      let url = `faqpublic/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getFAQSingle({ commit }, { pk }) {
      const res = await axiosIns.get(`faq/${pk}/`);
      return res;
    },
    async createFAQ({ commit }, payload) {
      const res = await axiosIns.post("faq/", payload);
      return res;
    },
    async updateFAQ({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`faq/${pk}/`, payload);
      return res;
    },
    async deleteFAQ({ commit }, { pk }) {
      const res = await axiosIns.delete(`faq/${pk}/`);
      return res;
    },

    //Quote
    async getQuotes({ commit }, { pageNumber, name, email }) {
      let url = `quote/?page=${pageNumber}`;
      if (name) {
        url += `&full_name=${name}`;
      }
      if (email) {
        url += `&email=${email}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },

    async createQuote({ commit }, payload) {
      const res = await axiosIns.post("quotepublic/", payload, {
        withCredentials: false, // Ensures no cookies are sent
        headers: {
          Authorization: "", // Ensures no token is sent
        },
      });
      return res;
    },

    //overseas quote
    async getOverseasQuote({ commit }, { pageNumber, name, isEmergency }) {
      let url = `overseaspakistanilist/?page=${pageNumber}`;
      if (name) {
        url += `&full_name=${name}`;
      }
      if (isEmergency) {
        url += `&is_emergency=${isEmergency}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },

    async createOverseasQuote({ commit }, payload) {
      const res = await axiosIns.post("overseaspakistani/", payload, {
        withCredentials: false, // Ensures no cookies are sent
        headers: {
          Authorization: "", // Ensures no token is sent
        },
      });
      return res;
    },

    //Testimonials

    async getTestimonial({ commit }, { pageNumber, name }) {
      let url = `testimonial/?page=${pageNumber}`;
      if (name) {
        url += `&full_name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getTestimonialPublic() {
      let url = `testimonialpublic/`;
      const res = await axiosIns.get(url);
      return res;
    },

    async createTestimonial({ commit }, payload) {
      const res = await axiosIns.post("testimonial/", payload);
      return res;
    },

    async updateTestimonial({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`testimonial/${pk}/`, payload);
      return res;
    },

    async deleteTestimonial({ commit }, { pk }) {
      const res = await axiosIns.delete(`testimonial/${pk}/`);
      return res;
    },

    //Referral Company

    async getRefferalCompany({ commit }, { pageNumber, name }) {
      let url = `company/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getRefferalCompanyPublic() {
      let url = `companypublic/`;
      const res = await axiosIns.get(url);
      return res;
    },

    async createRefferalCompany({ commit }, payload) {
      const res = await axiosIns.post("company/", payload);
      return res;
    },

    async updateRefferalCompany({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`company/${pk}/`, payload);
      return res;
    },

    async deleteRefferalCompany({ commit }, { pk }) {
      const res = await axiosIns.delete(`company/${pk}/`);
      return res;
    },

    //HEALTH PACKAGES

    async getHealthPackages({ commit }, { pageNumber, name }) {
      let url = `healthpackage/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getHealthPackagesPublic() {
      let url = `healthpackagepublic/`;
      const res = await axiosIns.get(url);
      return res;
    },

    async createHealthPackage({ commit }, payload) {
      const res = await axiosIns.post("healthpackage/", payload);
      return res;
    },

    async updateHealthPackage({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`healthpackage/${pk}/`, payload);
      return res;
    },

    async deleteHealthPackage({ commit }, { pk }) {
      const res = await axiosIns.delete(`healthpackage/${pk}/`);
      return res;
    },

    //cart add, remove
    addToCart({ commit }, item) {
      commit("addToCart", item);
    },
    removeFromCart({ commit }, item) {
      commit("removeFromCart", item);
    },
    clearCart({ commit }, item) {
      commit("clearCart", item);
    },
    decreaseDays({ commit }, item) {
      commit("decreaseDays", item);
    },
    increaseDays({ commit }, item) {
      commit("increaseDays", item);
    },
    async initiatePayment({ commit }, payload) {
      const res = await axiosIns.post("initiate_payment/", payload);
      return res;
    },
    async payment({ commit }, payload) {
      const res = await axiosIns.post("payment/", payload);
      return res;
    },
    async paymentConfirm({ commit }, payload) {
      const res = await axiosIns.post("paymentconfirm/", payload);
      return res;
    },
    async getSearchView({ commit }, { pageNumber, search }) {
      let url = `search/?page=${pageNumber}`;
      if (search) {
        url += `&search=${search}`;
      }
      const res = await axiosIns.get(url);

      return res;
    },

    async getDoctorsPublic({ commit }, { hospital, department, pageNumber }) {
      let url = `doctorpublic/?page=${pageNumber}`;

      if (hospital) {
        url += `&hospital=${hospital}`;
      }
      if (department) {
        url += `&department=${department}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },
    // Extra Functions
    async getDoctorsPublic2(
      { commit },
      { pageNumber, hospital, department, full_name, id }
    ) {
      let url = `doctorpublic/?page=${pageNumber}`;
      if (full_name) {
        url += `&full_name=${full_name}`;
      }
      if (hospital) {
        url += `&hospital=${hospital}`;
      }
      if (department) {
        url += `&department=${department}`;
      }
      if (id) {
        url += `&id=${id}`;
      }
      // if (full_name) {
      //   url = `doctorpublic/?full_name=${full_name}`;
      // }
      // if (id) {
      //   url = `doctorpublic/?id=${id}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
  },
};
