import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import vSelect from "vue-select";
import VueCompositionAPI from "@vue/composition-api";
import VueObserveVisibility from "vue-observe-visibility";

// import VueAnalytics from "vue-analytics";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import i18n from "@/libs/i18n";

// BSV Registration
Vue.use(BootstrapVue);

// Composition API
Vue.use(VueCompositionAPI);

//VueSelect
Vue.component("v-select", vSelect);

Vue.use(VueObserveVisibility);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

require("vue-select/dist/vue-select.css");

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

require("@core/scss/vue/pages/page-auth.scss");
require("@core/scss/vue/libs/vue-wizard.scss");
require("@core/scss/vue/libs/vue-select.scss");

Vue.config.productionTip = false;
// Vue.use(VueAnalytics, {
//   id: "G-969N0MEM0J",
//   router,
// });

Vue.directive("restrict", {
  bind(el, binding, vnode) {
    el.addEventListener("input", () => {
      const regex = binding.value;
      const value = el.value;
      setTimeout(() => {
        if (!regex.test(value)) {
          el.value = value.replace(/[^a-zA-Z ]/g, "");
          vnode.componentInstance.$emit("input", el.value);
        }
      }, 0);
    });
  },
});
// document.addEventListener("contextmenu", (event) => {
//   event.preventDefault();
// });

// Disable developer tools keyboard shortcuts
// document.addEventListener("keydown", (event) => {
//   if (
//     (event.ctrlKey &&
//       event.shiftKey &&
//       (event.key === "I" || event.key === "J" || event.key === "C")) || // Ctrl + Shift + I/J/C
//     event.key === "F12" // F12
//   ) {
//     event.preventDefault();
//   }
// });
function checkAuth() {
  return new Promise(async (resolve) => {
    try {
      await store.dispatch("appData/getCurrentUser");
      resolve();
    } catch (error) {
      resolve();
    }
  });
}

function createApp() {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}

function createBeforeEach() {
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.getters["appData/isLoggedIn"]) {
        if (to.meta.permission) {
          if (store.getters["appData/hasPermission"](to.meta.permission)) {
            next();
          } else {
            next({ name: "PatientDashboard" });
          }
        } else {
          next();
        }
      } else {
        next({ name: "Login" });
      }
    } else {
      next();
    }
    // if (
    //   !to.matched.some((record) => record.meta.requiresAuth) &&
    //   store.getters["appData/isLoggedIn"]
    // ) {
    //   next({ name: "User" });
    // } else {
    //   next();
    // }
  });
}

checkAuth().then(() => {
  createBeforeEach();
  createApp();
});
